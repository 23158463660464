class IpConfig {
    constructor(config) {
        this.config = config;
        this.init();
    }

    async init() {
        this.ipInput = document.getElementsByClassName(
            this.config.ipInputClass
        )[0];
        this.errorMessage = this.config.errorMessage;
        this.ipAddress = await this.getIPAddress();
        this.ipInput.value = this.ipAddress;
    }

    // Get IP Address from an external link to fetch the global IP
    async getIPAddress() {
        try {
            console.log("Fetching IP");
            const response = await fetch("https://europe-north1-heyflow-custom-solutions.cloudfunctions.net/fetchIp");
            const data = await response.json();
            if (data.ip) {

                return data.ip;
            } else {
                alert(this.errorMessage);
            }
        } catch (error) {
            console.error("Error:", error);
        }
    }
}

window.addEventListener("heyflow-init", () => {
    const ipConfig = new IpConfig({
        ipInputClass: "extern-Ip-Addr-Class",
        errorMessage: "Ip could not be retrieved.",
        errorAPIExec: "Posting to API was not possible",
    });
});

window.addEventListener("heyflow-submit", ({
    fieldsSimple
}) => {
    /*// console.log("debug: code block:");
    // console.log(document.querySelectorAll('.inner-narrow [data-blocktype="html"]'));
    let codeBlock = document.querySelectorAll('.inner-narrow [data-blocktype="html"]')[0];
    let sovendusContainer = document.createElement("div");
    sovendusContainer.setAttribute("id", "sovendus-container-1");
    codeBlock.appendChild(sovendusContainer);
    
	window.sovIframes = window.sovIframes || [];

	window.sovIframes.push({

		trafficSourceNumber : '1588',

		trafficMediumNumber : '59',

		iframeContainerId : 'sovendus-container-1'

	});
    // This contains all the values https://www.heyflow.help/en/articles/6885210-heyflow-events-api
    console.log(fieldsSimple);
    
    const firstName = document.querySelector("input[data-variable='vorname']").value;
    const lastName = document.querySelector("input[data-variable='name']").value;
    const email = document.querySelector("input[data-variable='email']").value;
    const plz = document.querySelector("input[data-variable='plz']").value;
    const telefon1 = document.querySelector("input[data-variable='telefon1']").value;

    window.sovConsumer = window.sovConsumer || {};

    window.sovConsumer = {

        consumerSalutation: '',

        consumerFirstName: firstName,

        consumerLastName: name,

        consumerEmail: email,

        consumerStreet: '',

        consumerStreetNumber: '',

        consumerCountry: '',

        consumerZipcode: plz,

        consumerCity: '',

        consumerPhone: telefon1,

        consumerYearOfBirth: ''

    };
    var sovDomain = window.location.protocol + '\x2F\x2F' + 'api.sovendus.com';

	var sovJsFile = sovDomain + '\x2Fsovabo\x2Fcommon\x2Fjs\x2FflexibleIframe.js';

	document.write('<sc' + 'ript async="true" src="' + sovJsFile + '" type="text/javascript"></sc' + 'ript>');*/
})